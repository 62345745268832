import BasePlugin from '../BasePlugin'
import AddUserWindow from './AddUserWindow'

export default class AddUserCommand extends BasePlugin {
  async execute () {
    let model = {
      surname: null,
      name: null,
      midname: null,
      email: null,
      // department: null,
      position: null
    }
    const h = this.context.$createElement
    let addUserWindow = h(AddUserWindow, { key: Math.random(), props: { model: model } })
    this.context.$msgbox({
      title: 'Добавление нового сотрудника',
      message: h('div', { key: Math.random() }, [
        addUserWindow
      ]),
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Добавить',
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          try {
            if (await addUserWindow.componentInstance.isValid()) {
              let users = await this.context.$http.get(`${this.context.$config.api}/accesseditor/users?fields=email`)
              let isIssetUser = users.data.find((item) => item.email === model.email)
              if (isIssetUser) {
                this.context.$message.error('Сотрудник был добавлен в систему ранее, дублирование пользователей запрещено')
              } else {
                let password = await this.context.$http.get(`${this.context.$config.api}/accesseditor/users/password/generate`)
                let newUser = await this.context.$http.post(`${this.context.$config.api}/accesseditor/users`, {
                  name: model.name,
                  surname: model.surname,
                  midname: model.midname,
                  email: model.email,
                  login: model.email.split('@')[0],
                  password: password.data.password,
                  role_id: 141
                }, {
                  hideNotification: true })

                await this.context.$http.put(`${this.context.$config.api}/registryservice/registry/963/records/${newUser.data.id}`, {
                  // attr_967_: model.department,
                  attr_977_: model.position,
                  object_id: 963,
                  id: newUser.data.id
                }, {
                  hideNotification: true,
                  headers: {
                    'Content-Type': 'application/json'
                  }
                })
                let attributes = ['attr_1048_', 'attr_3103_', 'attr_4109_']
                attributes.forEach((item) => {
                  if (typeof this.context.getModel()[item] !== 'undefined') {
                    this.context.getModel()[item] = newUser.data.id
                  }
                })

                done()
              }
            } else {
              console.log('error')
            }
          } catch (e) {
            this.context.$message.error('Ошибка создания пользователя')
            console.log(e)
          } finally {
            instance.confirmButtonLoading = false
          }
        } else {
          done()
        }
      }
    }).then(action => {

    })
  }
}

<template>
    <el-form :rules="rules" :model="localModel" label-position="left" label-width="110px" ref="form">
        <el-form-item label="Фамилия" prop="surname">
            <el-input v-model="localModel.surname"></el-input>
        </el-form-item>
        <el-form-item label="Имя" prop="name">
            <el-input v-model="localModel.name"></el-input>
        </el-form-item>
        <el-form-item label="Отчество" prop="midname">
            <el-input v-model="localModel.midname"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
            <el-input v-model="localModel.email" name="email"></el-input>
        </el-form-item>
        <!--<el-form-item label="Подразделение" prop="department">
            <xref-field margin="-25px 0px 0px 0px" v-model="localModel.department" name="attr_967_"></xref-field>
        </el-form-item>-->
        <el-form-item label="Должность" prop="position">
            <el-input v-model="localModel.position"></el-input>
        </el-form-item>
    </el-form>
</template>

<script>
// import xrefField from '@/components/InterfaceEditor/components/registry/xref_field'
export default {
  name: 'add-user-window',
  components: {
    // xrefField
  },
  provide: {
    isEditor: () => false
  },
  props: {
    model: {
      type: Object
    }
  },
  data () {
    return {
      localModel: this.model,
      rules: {
        surname: [
          { required: true, message: 'Введите фамилию', trigger: 'blur' }
        ],
        name: [
          { required: true, message: 'Введите имя', trigger: 'blur' }
        ],
        midname: [
          { required: true, message: 'Введите отчество', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Веедите email адрес', trigger: 'blur' },
          { type: 'email', message: 'Пожалуйста, введите корректный email адрес', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    isValid () {
      return this.$refs.form.validate()
    }
  },
  watch: {
    localModel: {
      handler (value) {
        this.model = value
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
